.container {
  width: min(100vw, 600px);
  min-width: 600px;
  z-index: 99999;
  align-items: center;
  display: flex;
  height: 400px;
  justify-content: center;
  top: 0;

  .steam-animate {
    animation: l2 2s infinite linear;

    svg {
      height: 150px;
      width: 150px;
    }
  }

  @keyframes l2 {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}
